import React, { useState, useEffect, useRef } from "react";
import { VonageClient } from "@vonage/client-sdk";
import axios from "axios";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  styled,
} from "@mui/material";
import { getCCClientCredentials } from "./getCCClientCredentials"; // Import the function
import ConfirmDialog from "./ConfirmDialog";

// Custom styling to align text to the left in the dropdown
const StyledMenuItem = styled(MenuItem)({
  textAlign: "left",
});

const CustomSelect = styled(Select)({
  textAlign: "left",
});

const getStatusBorderColor = (status: string) => {
  switch (status) {
    case "Error fetching token":
    case "Error creating session":
    case "Error during branding":
    case "Error making call":
      return "red";
    case "Call is ringing":
    case "Call answered":
      return "green";
    case "Branding in progress...":
    case "Initalising...":
    case "Creating session...":
      return "orange";
    default:
      return "green";
  }
};

const CallManager: React.FC = () => {
  const [callPhoneNumber, setCallPhoneNumber] = useState<string>("");
  const [fromPhoneNumber, setFromPhoneNumber] = useState<string>("12012413481");
  const [callId, setCallId] = useState<string | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [status, setStatus] = useState<string>("");
  const [token, setToken] = useState<string | null>(null);
  const [client, setClient] = useState<VonageClient | null>(null);
  const [callIntent, setCallIntent] = useState<string>(""); // Added state for call intent
  const [customIntent, setCustomIntent] = useState<string>(""); // Added state for custom intent
  const [confirmOpen, setConfirmOpen] = useState(false);
  const displayName = "cc-user"; // This can be dynamic if needed
  const agentPhoneNumber = "cc-user";
  const tokenFetchedRef = useRef<boolean>(false); // Ref to track if the token has been fetched

  // New state for SMS
  const [smsToNumber, setSmsToNumber] = useState<string>("");
  const [smsMessage, setSmsMessage] = useState<string>("");

  useEffect(() => {
    // Fetch the JWT token from the server when the component mounts
    const fetchToken = async () => {
      setStatus("Initalising...");
      try {
        const response = await fetch(
          "https://lguptbn9q8hu.share.zrok.io/getCredential-v1",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              displayName,
              phoneNumber: agentPhoneNumber,
            }),
          }
        );
        const data = await response.json();
        console.log(`token... ${data.securedCallsResponse.data.token}`);
        setToken(data.securedCallsResponse.data.token);
        setStatus("");
      } catch (error) {
        console.error("Error fetching token:", error);
        setStatus("Error fetching token");
      }
    };

    if (!tokenFetchedRef.current) {
      fetchToken();
      tokenFetchedRef.current = true; // Set the ref to true after fetching the token
    }
  }, []); // Empty dependency array ensures this runs only once

  useEffect(() => {
    if (token) {
      setStatus("Creating session...");
      const vonageClient = new VonageClient();
      setClient(vonageClient);

      vonageClient
        .createSession(token)
        .then((id: string) => {
          console.log("Id of created session: ", id);
          setSessionId(id);
          setStatus("");
        })
        .catch((error: Error) => {
          console.error("Error creating session: ", error);
          setStatus("Error creating session");
        });

      vonageClient.on(
        "legStatusUpdate",
        (callId: string, legId: string, status: string) => {
          console.log(
            `Leg Status : ${status} for callID ${callId} and legId ${legId}`
          );

          if (status === "RINGING") {
            setCallId(callId);
            setStatus("Call is ringing");
          }
          if (status === "ANSWERED") {
            setCallId(callId);
            setStatus("Call answered");
          }
          if (status === "COMPLETED") {
            setCallId(null);
            setStatus("Call completed");
          }
        }
      );
    }
  }, [token]);

  const makeCall = async () => {
    const intent = callIntent === "custom" ? customIntent : callIntent;

    if (callPhoneNumber !== "" && intent !== "") {
      try {
        setStatus("Branding in progress...");
        const accessToken = await getCCClientCredentials();
        const body = {
          brandedNumber: fromPhoneNumber,
          consumerIdentifier: callPhoneNumber,
          intent: intent,
          callSetUpFor: "InAppCall",
        };
        const headers = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };

        const brandingUrl =
          "https://api.development.securedcalls.com/branding/brand";

        console.log(`URL ${brandingUrl}`);
        console.log(`Body ${JSON.stringify(body)}`);
        console.log(`Headers ${JSON.stringify(headers)}`);

        const brandingResponse = await axios.post(brandingUrl, body, headers);
        setStatus("Branding successful...");
        console.log("Branding successful:", brandingResponse.data);
        startCall();
      } catch (error) {
        console.error("Error during branding:", error);
        setStatus("Error during branding");
        setConfirmOpen(true);
      }
    } else {
      setStatus("Please enter the phone number and select the call intent.");
    }
  };

  const startCall = () => {
    console.log(`now calling.. ${fromPhoneNumber} to ${callPhoneNumber}`);
    const intent = callIntent === "custom" ? customIntent : callIntent;
    client
      ?.serverCall({
        to: callPhoneNumber,
        from: fromPhoneNumber,
        intent,
        isDemo: true,
      })
      .then((_callId: string) => {
        setCallId(_callId);
        setStatus("Calling...");
      })
      .catch((error: Error) => {
        console.error(`Error making call: ${error}`);
        setStatus("Error making call");
      });
  };

  const handleConfirmClose = (result: boolean) => {
    setConfirmOpen(false);
    if (result) {
      startCall();
    }
  };

  const hangUp = () => {
    if (callId) {
      client
        ?.hangup(callId)
        .then(() => {
          console.log(`call ${callId} ended`);
          setStatus("Call ended");
          setCallId(null);
        })
        .catch((error: Error) => {
          console.error("Error hanging up call:", error);
          setStatus("Error hanging up call");
        });
    }
  };

  // Function to send SMS
  const sendSMS = async () => {
    try {
      const response = await axios.post(
        "https://lguptbn9q8hu.share.zrok.io/send-sms",
        {
          from: "447451272583",
          to: callPhoneNumber,
          text: smsMessage,
        }
      );
      console.log("SMS sent successfully:", response.data);
      setStatus("SMS sent successfully");
    } catch (error) {
      console.error("Error sending SMS:", error);
      setStatus("Error sending SMS");
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          backgroundColor: "#007bff", // Set your desired background color
          color: "white",
          padding: "20px",
          borderRadius: "8px 8px 0 0", // Rounded top corners
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: `5px solid ${getStatusBorderColor(status)}`, // Conditionally set the bottom border color
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Softphone
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#EBEBEB", // Set your desired background color
          color: "white",
          padding: "20px",
          borderRadius: "0px 0px 8px 8px", // Rounded bottom corners
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Enter customer's phone number"
              value={callPhoneNumber}
              onChange={(e) => setCallPhoneNumber(e.target.value)}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              variant="filled"
              sx={{ backgroundColor: "white" }}
            >
              <InputLabel id="call-intent-label">Call Intent</InputLabel>
              <CustomSelect
                labelId="call-intent-label"
                value={callIntent}
                onChange={(e) => setCallIntent(e.target.value as string)}
                label=""
              >
                <StyledMenuItem value="">
                  <em>None</em>
                </StyledMenuItem>
                <StyledMenuItem value="Sales">Sales</StyledMenuItem>
                <StyledMenuItem value="Support">Support</StyledMenuItem>
                <StyledMenuItem value="General Inquiry">
                  General Inquiry
                </StyledMenuItem>
                <StyledMenuItem value="custom">Custom</StyledMenuItem>
              </CustomSelect>
            </FormControl>
          </Grid>
          {callIntent === "custom" && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Enter custom intent"
                value={customIntent}
                onChange={(e) => setCustomIntent(e.target.value)}
                variant="filled"
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={makeCall}
              disabled={
                !sessionId || fromPhoneNumber === "" || callPhoneNumber === ""
              }
            >
              Call
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="error"
              onClick={hangUp}
              disabled={!callId}
            >
              Hang Up
            </Button>
            {status && (
              <Box mt={2}>
                <Alert severity="info">{status}</Alert>
              </Box>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h2" component="h1" gutterBottom>
              SMS
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Enter your message"
              value={smsMessage}
              onChange={(e) => setSmsMessage(e.target.value)}
              variant="filled"
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={sendSMS}
              disabled={!callPhoneNumber || !smsMessage}
            >
              Send SMS
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ConfirmDialog open={confirmOpen} onClose={handleConfirmClose} />
    </Container>
  );
};

export default CallManager;
