import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Alert,
  Link,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";

const Root = styled("div")({
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#2d2d2d",
});

const CustomPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: "center",
  backgroundColor: "#3a3a3a",
  color: "#fff",
  borderRadius: theme.shape.borderRadius,
}));

const Logo = styled("img")(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "& .MuiInputBase-root": {
    color: "#fff",
  },
  "& .MuiInputLabel-root": {
    color: "#bdbdbd",
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const CustomLink = styled(Link)(({ theme }) => ({
  color: "#bdbdbd",
  marginTop: theme.spacing(1),
  display: "block",
}));

const CustomAlert = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const users = [
  { username: "agent1", password: "password1" },
  { username: "agent2", password: "password2" },
];

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  const handleLogin = () => {
    const user = users.find(
      (u) => u.username === username && u.password === password
    );
    if (user) {
      navigate("/call");
    } else {
      setError("Invalid username or password");
    }
  };

  return (
    <Root>
      <CustomPaper elevation={3}>
        <Logo
          src="https://sc-demo-stack-securedcallsdemofiles8627e022-ntgcxeufhtcb.s3.ap-southeast-2.amazonaws.com/sc-logo.png"
          width={100}
          height={100}
          alt="Logo"
        />
        <Typography variant="h5" gutterBottom>
          Login
        </Typography>
        <CustomTextField
          fullWidth
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          variant="outlined"
        />
        <CustomTextField
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          variant="outlined"
        />
        <CustomButton fullWidth variant="contained" onClick={handleLogin}>
          Login
        </CustomButton>
        {error && <CustomAlert severity="error">{error}</CustomAlert>}
        <CustomLink href="#" variant="body2">
          Forgot password?
        </CustomLink>
      </CustomPaper>
    </Root>
  );
};

export default Login;
