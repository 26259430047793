import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    backgroundColor: "#ff6b6b", // Red background color
    color: "white",
    padding: theme.spacing(2),
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  color: "white",
  fontWeight: "bold",
}));

const StyledDialogContentText = styled(DialogContentText)(({ theme }) => ({
  color: "white",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "black",
  color: "white",
  "&:hover": {
    backgroundColor: "#333",
  },
  margin: theme.spacing(1),
}));

interface ConfirmDialogProps {
  open: boolean;
  onClose: (result: boolean) => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, onClose }) => {
  return (
    <StyledDialog open={open} onClose={() => onClose(false)}>
      <StyledDialogTitle>Confirmation</StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText>
          Branding has not worked, do you wish to continue with the call?
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={() => onClose(true)} autoFocus>
          Yes
        </StyledButton>
        <StyledButton onClick={() => onClose(false)}>No</StyledButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default ConfirmDialog;
