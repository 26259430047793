import axios from 'axios';
import qs from 'qs';

export async function getCCClientCredentials() {
    // Define your client credentials and the Cognito token endpoint URL
    const clientId = "s7co1aq8ugt0c1gsrp613cdeo";
    const clientSecret = "1coad3hoqsrakme4mrqst335ti11olvf3b7j4j3sh49fllioc8uq";
    const userPoolDomain = "dev-cc-expertstack.auth.ap-southeast-2.amazoncognito.com";
    const tokenEndpoint = `https://${userPoolDomain}/oauth2/token`;

    // Encode the client credentials in Base64 using btoa
    const credentials = `${clientId}:${clientSecret}`;
    const base64Credentials = btoa(credentials);

    // Set the request headers and body
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${base64Credentials}`,
    };

    const requestBody = qs.stringify({
        grant_type: 'client_credentials',
    });

    console.log(`header : ${JSON.stringify(headers)}`);
    console.log(tokenEndpoint);

    try {
        // Make the HTTP POST request to get the OAuth token
        const response = await axios.post(tokenEndpoint, requestBody, { headers });
        const accessToken = response.data.access_token;
        console.log('Access Token:', accessToken);
        return accessToken;
    } catch (error) {
        console.error(
            'Error:',
            error
        );
        throw error; // Re-throw the error so it can be handled by the caller
    }
}