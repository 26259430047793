import React from "react";
import { Outlet, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTheme, Theme } from "@mui/material/styles";
import logo from "./images/sc-logo-blue.png"; // Replace with the path to your logo image

const drawerWidth = 240;

const Main = styled("main")(({ theme }: { theme: Theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  marginLeft: drawerWidth,
}));

const AppBarStyled = styled(AppBar)(({ theme }: { theme: Theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const DrawerStyled = styled(Drawer)(({ theme }: { theme: Theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
  },
}));

const MasterPage: React.FC = () => {
  const theme = useTheme<Theme>();

  return (
    <Box sx={{ display: "flex" }}>
      <AppBarStyled position="fixed" theme={theme}>
        <Toolbar>
          <img
            src={logo}
            alt="Logo"
            style={{ marginRight: "10px", height: "40px" }}
          />
          <Typography variant="h6" noWrap>
            S.C CONTACT CENTRE
          </Typography>
        </Toolbar>
      </AppBarStyled>
      <DrawerStyled
        variant="permanent"
        theme={theme}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        <Toolbar />
        <List>
          <ListItem button component={Link} to="/call">
            <ListItemText primary="Call Manager" />
          </ListItem>
          <ListItem button component={Link} to="/analytics">
            <ListItemText primary="Analytics" />
          </ListItem>
          <ListItem button component={Link} to="/settings">
            <ListItemText primary="Settings" />
          </ListItem>
        </List>
      </DrawerStyled>
      <Main theme={theme}>
        <Toolbar />
        <Outlet />
      </Main>
    </Box>
  );
};

export default MasterPage;
